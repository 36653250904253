<template>
  <div class="page">
    <div class="banner">
      <img class="img" src="@/static/become/flow.png" />
    </div>
    <div class="content">
      <div class="view">
        <div class="title-view">
          <div class="left-icon"></div>
          加盟店
          <div class="left-icon"></div>
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="(item, i) in list" :key="i">
          <img class="item-img" :src="$host+item.image" />
          <div class="item-title">{{ item.store_name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      params: {
        page: 1,
        limit: 9,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http.getstorelist(this.params).then((res) => {
        let {msg, code, data} = res
        if (code == 1) {
          this.list = data.items;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.page {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.view {
  width: 1200px;
  margin: 20px 0;
}

.title-view {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  height: 56px;
  color: #333;
  .left-icon {
    width: 120px;
    height: 5px;
    background: #fe6039;
    opacity: 0.75;
    border-radius: 2.5px;
    margin: 0 20px;
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  .item {
    width: 400px;
    padding: 10px 20px;
    margin-bottom: 10px;
    cursor: pointer;
    .item-img {
      width: 360px;
      height: 240px;
    }
    .item-title {
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        text-align: center;
        color: #333;
        font-weight: bold;
    }
  }
}

.banner {
  height: 700px;
  .img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
